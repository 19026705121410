//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: 											#ffffff;

// Theme colors
// Primary
$primary:       									#003366;
$primary-active:    								#003366;
$primary-light:    								    #62a5e9;
$primary-light-dark:    							#212e48;
$primary-inverse:  									$white;

// Secondary
$secondary:											#FAD127;
$secondary-active:									#FAD127;

$light-blue:										#62a5e9;

// Success
$success:       									#50cd89;
$success-active:    								#47be7d;
$success-light:    									#e8fff3;
$success-light-dark:    							#1c3238;
$success-inverse:  									$white;

// Info
$info:       									    #62a5e9;
$info-active:    									#5014d0;
$info-light:    									#f8f5ff;
$info-light-dark:    								#2f264f;
$info-inverse:  									$white;

// Danger
$danger:       									    #f1416c;
$danger-active:    									#d9214e;
$danger-light:    									#fff5f8;
$danger-light-dark:    								#3a2434;
$danger-inverse:  									$white;

// Warning
$warning:       									#ffc700;
$warning-active:    								#f1bc00;
$warning-light:    									#fff8dd;
$warning-light-dark:    							#392f28;
$warning-inverse:  									$white;

// Remove Rounded
$enable-rounded: 									false;

// Reduce padding
$app-container-padding-x:							0px;

// Font family
$font-family-sans-serif: Montserrat, "Proxima Nova", "Open Sans", "sans-serif" !default;

$gray-900: 											#232424;

$teal:												#20c997;
@import url('https://fonts.googleapis.com/css?family=Montserrat:200,400,500,600,700');
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import 'mapbox-gl/dist/mapbox-gl.css';

/* [1] The container */
.img-hover-zoom {
  // height: 300px; /* [1.1] Set it as per your need */
  overflow: hidden;
  /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}

.image-gallery-thumbnail-image {
  object-fit: contain
}

.image-gallery-image {
  height: 400px;
}

.fullscreen .image-gallery-image {
  height: auto;
}

.z-i-top {
  z-index: 5000;
}

.text-truncate-two {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-three {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-four {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-five {
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-ten {
  -webkit-line-clamp: 10;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.shadow-bottom {
  box-shadow: 0 4px 4px -4px gray;
}

.bg-gradient-black {
  background: linear-gradient(to top,
      black,
      rgba(255, 0, 0, 0))
}

.pubHeader {
  border-left: 7px solid $secondary;

}


.square {
  background: $primary;
  clip-path: polygon(0px 0px, 100% 0%, 20% 200%, 0% 100%);

}

.carouselArrowRight {}

#kt_app_content {
  padding-top: 0px;

}

.hover-blue:hover {
  background-color: $primary !important;
  color: $secondary !important;
}

.app-page {
  background-color: white;
}

.opacity-67 {
  opacity: 67%;
}

.overlay-background {}

.mh-25rem {
  max-height: 25rem;
}

.whyTitle::after {
  content: "";
  display: block;
  position: relative;
  top: 19px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 18px;
  margin: 0px auto;
  border-color: $primary;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)
}

.borderDiamond::after {
  content: "";
  display: block;
  position: relative;
  top: 19px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 18px;
  margin: 0px auto;
  border-color: $primary;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)
}

.rectangleShadow {
  box-shadow: rgb(0 0 0 / 75%) 5px 5px 0px 0px;
}

.challengerTitle::after {
  content: "";
  display: block;
  position: relative;
  top: 19px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 18px;
  margin: 0px auto;
  border-color: $white;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)
}

.contactForm {
  position: relative;
}

.uppercase {
  text-transform: uppercase;
}

.hide-button[aria-expanded="true"] {
  display: none;
}

.hide-button[aria-expanded="false"] {
  display: inline;
}

.client-list {
  height: 40px;
  max-width: 250px;
}

.shadow-card {
  text-shadow: 1px 1px #000;
}

@include media-breakpoint-down(sm) {
  .career-banner {
    max-height: 50rem;
  }

  .banner-image {
    width: 100%
  }
}

@include media-breakpoint-between(sm, md) {
  .career-banner {
    max-height: 40rem;
  }

  .banner {
    height: 15rem;
  }

  .banner-image {
    width: 75%;
    margin: auto;
  }
}

@include media-breakpoint-up(md) {
  .career-banner {
    max-height: 40rem;
  }

  .banner {
    height: 10rem;
  }

  .banner-image {
    width: 100%
  }
}

@include media-breakpoint-up(lg) {
  .career-banner {
    max-height: 40rem;
  }

  .banner {
    height: 10rem;
  }

  .banner-image {
    width: 100%
  }
}

.bgContactForm {
  content: "";
  top: 0%;
  left: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  background-color: rgb(242, 242, 245);
  clip-path: polygon(0px 0px, 100% 25%, 100% 100%, 0% 100%);
}

ul.nav.nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;

}

.height-10 {
  height: 10rem;
}

.height-5 {
  height: 5rem;
}

.height-2 {
  height: 2rem;
}

.nav.nav-tabs .nav-link.active {
  background-color: $primary;
  color: $secondary;
}

.button-transition {
  background-color: white;
  border-color: #62a5e9;
  color: #62a5e9;
}

.button-transition:hover {
  background-color: $primary;
  color: $secondary;
}

.button-transition-primary {
  background-color: white;
  color: $primary;
}

.button-transition-primary:hover {
  background-color: $primary;
  color: $secondary;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.pressHero {
  background-image: "https://www.primephilippines.com/static/640_press-room-hero-image-alt-59706d7f9a07a73528eebf71bc49a721.webp"
}

.fullWidth {
  position: absolute;
  width: 100vw;
  height: 50rem;
  top: 0;
  left: 0;
}

.fullHeight {
  height: 100vh;
}

.carousel .carousel-indicators button {
  background-color: white !important;
  border: solid !important;
  border-width: thin;
}

.carousel .carousel-indicators button.active {
  background-color: $primary !important;
  border: solid !important;
  border-width: thin;
  border-color: $primary;
}

// .carousel-button {
//   background-color: white !important;
//   border: solid !important;
//   border-color: black !important;
// }

.bubble {
  margin-right: 11rem;
  position: relative;
}

.bubble:after {
  content: '';
  width: 10rem;
  border-bottom: solid 1px #fff;
  position: absolute;
  left: 2rem;
  top: 50%;
  z-index: 1;
}

@media (max-width: 576px) {
  .bubble:after {
    content: '';
    border: 1px solid white;
    width: 20px;
    position: absolute;
  }
}

.bubble:hover {
  border-color: $primary;
  color: $primary;
}

.thumb-custom {
  height: 15px;
  width: 15px;
  text-align: center;
  background: white;
  border: 3px solid $primary;
  cursor: pointer;
  border-radius: 100%;
  display: block;
}

.horizontal-slider {
  width: 100%;
  height: 10px;
  max-width: 500px;
}

.track-custom {
  height: 4px;
  position: relative;
  top: 5px;
  border-radius: 999px;
  background: #ddd
}

.track-custom.track-custom-1 {
  background-color: $primary;
}

.track-custom.track-custom-0 {
  background-color: #ddd;
}

.track-custom.track-custom-2 {
  background-color: #ddd;
}

.search-button {
  background: $primary;
  color: white;

}

.search-button:hover {
  background: #184b7e;
  color: white;
}

.input-blue {
  background-color: rgb(236, 247, 255);
  border: 0px
}

.input-blue:hover {
  background-color: rgb(236, 247, 255);
  border: 1px
}

.sticky-item {
  position: sticky;
}

.sideFixed {
  position: fixed;
  left: 0;
  top: 250px;
  z-index: 10000;
}

.bgTransparentTransition {
  background-color: rgba(0, 0, 0, 0) !important;
  transition: background-color 200ms linear;
}

.bgWhiteTransition {
  background-color: #fff !important;
  transition: background-color 200ms linear;
}

.bg-image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;

}

.topOutline {
  position: absolute;
  width: 100rem;
  height: 100%;
  z-index: 1;
  margin: 0 auto;
}

.aboveBG {
  z-index: 100;
}

.innerOutline {
  position: absolute;
  width: 100rem;
  height: 100%;
  z-index: 5;
  margin: 0 auto;
}

.bg-image .mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #fff;
}

.bg-image .mask .text {
  height: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.bg-image .mask .text p {
  margin: 0;
}


.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  cursor: default;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 51, 102, 0.7);
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out
}

.hovereffect img {
  display: block;
  position: relative;
}

.hovereffect h2 {
  text-transform: capitalize;
  color: #fff;
  position: relative;
  font-size: 17px;
  -webkit-transform: translatey(-100px);
  -ms-transform: translatey(-100px);
  transform: translatey(-100px);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  padding: 10px;
}

.hovereffect a.info {
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  margin: 50px 0 0;
  padding: 7px 14px;
}

.hovereffect a.info:hover {
  box-shadow: 0 0 5px #fff;
}

.hovereffect:hover img {}

.linewrapper {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  background-size: contain;
  width: 250px;
  opacity: 0.2;
}

.linewrapperflip {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  background-size: contain;
  width: 250px;
  transform: scaleX(-1);
  opacity: 0.2;
}

.textShadow {
  text-shadow: #000 1px 0 3px;
}

.textFade {

  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
}

.hovereffect:hover h2,
.hovereffect:hover a.info {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-transform: translatey(0);
  -webkit-transform: translatey(0);
  transform: translatey(0);
}

.hovereffect:hover a.info {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.scroll-downs {
  position: absolute;
  top: 350px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  width: 34px;
  height: 55px;
}

.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid $secondary;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}

.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15, .41, .69, .94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }

  10% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

// #partnersCarousel {
//   height: 20rem;
//   width: 100%;
//   background-image: "https://www.primephilippines.com/images/awards-affiliates/640_bg-affiliation.webp"
// }

// .offcanvasItem {
//   z-index: 1051;
//   position: relative;
// }

// Font family
$font-family-sans-serif: Montserrat, "Proxima Nova", "Open Sans", "sans-serif" !default;

$gray-900: #232424;

$teal: #20c997;

.text-teal {
  color: #20c997;
}

$theme-colors: (
  "teal": $teal,
);

.padding-table-columns td {
  padding: 0 20rem 1rem 0;
}

;